import React, { Component } from 'react';
import Image from "react-graceful-image";
import './App.css'

import underline from './images/underline.png'
import two_minds_cover_drawing from './images/two-minds_cover_drawing.png'
import two_minds_handwritten from './images/two-minds-handwritten.png'

class HomePage extends Component {
	render() {
		return (
			<div className="App-content">
				<a href="https://sheepdogwolf.bandcamp.com/album/two-minds"><Image src={two_minds_cover_drawing} noLazyLoad="true" placeholderColor="#fff" className="App-logo" alt="Two-Minds cover image"/></a>
				<a href="https://sheepdogwolf.bandcamp.com/album/two-minds"><Image src={two_minds_handwritten} noLazyLoad="true" placeholderColor="#fff" className="App-home-text" alt="Two-Minds"/></a>
				<Image src={underline} noLazyLoad="true" placeholderColor="#fff" className="App-underline" alt="Green paint underline"/>
			</div>
			);
	}
}

export default HomePage;