import React, { Component } from 'react';
import './App.css'

import cyclical_single_artwork from './images/cyclical-single-artwork-cropped.jpg'

class CyclicalSmartLinks extends Component {
	render() {
		return (
			<div className="Smart-links">
                <a href="https://www.youtube.com/watch?v=N4Olc-FlDq0&feature=youtu.be"><img src={cyclical_single_artwork} className="Smart-link-single-artwork"/></a>
                <br></br>
				<a href="https://www.youtube.com/watch?v=N4Olc-FlDq0&feature=youtu.be"><btn className="Smart-link-button">LYRICS VIDEO</btn></a>
				<br></br>
				<a href="https://open.spotify.com/album/4C9Mg3Xxl3pqvV5CtvnlH4"><btn className="Smart-link-button">SPOTIFY</btn></a>
				<br></br>
				<a href="https://music.apple.com/nz/album/cyclical-single/1531648020"><btn className="Smart-link-button">APPLE MUSIC</btn></a>
				<br></br>
				<a href="https://sheepdogwolf.bandcamp.com/track/cyclical"><btn className="Smart-link-button">BANDCAMP</btn></a>
				<br></br>
			</div>
			);
	}
}

export default CyclicalSmartLinks;