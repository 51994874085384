import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Image from "react-graceful-image";
import {
	Link
} from 'react-router-dom'
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom'

import header_image from './images/sheepdogwolf_handwritten.png'
import './App.css';

import NavigationBar from './NavigationBar.js'
import HomePage from './HomePage.js'
import MusicPage from './MusicPage.js'
import VideosPage from './VideosPage.js'
import AboutPage from './AboutPage.js'
import ContactPage from './ContactPage.js'
import SocialMediaBar from './SocialMediaBar.js'

import CyclicalSmartLinks from './CyclicalSmartLinks.js'

class App extends Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Helmet>
						<meta charSet="utf-8" />
						<title>Sheep, Dog & Wolf</title>
						<meta name="description" content="Sheep, Dog & Wolf is the solo project of multi-instrumentalist, vocalist, and composer Daniel McBride."/>
					</Helmet>
					<div className="App-header">
						<li className="Link-image"><Link to="/"><Image src={header_image} noLazyLoad="true" placeholderColor="#fff" className="App-header-image" alt="header"/></Link></li>
						<NavigationBar/>
					</div>
					<Route exact path="/" component={HomePage}/>
					<Route path="/music" component={MusicPage}/>
					<Route path="/videos" component={VideosPage}/>
					<Route path="/about" component={AboutPage}/>
					<Route path="/contact" component={ContactPage}/>
					<Route path="/cyclical" component={CyclicalSmartLinks}/>
					<SocialMediaBar/>
				</div>
			</Router>
		);
	}
}

export default App;
