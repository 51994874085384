import React, { Component } from 'react';
import './App.css'

class SocialMediaBar extends Component {
	render() {
		return(
			<div className="Social-media-bar">
				<a href="https://open.spotify.com/artist/49BJFNmL0Qb0eUEROwcTlO?si=bAGgaj1aRUSMCcNmvGrv0w"><svg className="Social-media-icon" viewBox="0, 0, 400,400"><g><path id="path0" d="M160.000 3.985 C 3.298 39.331,-52.829 227.625,59.773 340.227 C 189.552 470.006,409.787 371.263,399.188 188.048 C 392.193 67.121,276.114 -22.206,160.000 3.985 M254.873 122.304 C 320.293 138.691,346.249 157.751,328.000 176.000 C 321.031 182.969,317.259 182.737,300.638 174.314 C 253.693 150.526,183.738 142.564,118.810 153.621 C 87.317 158.984,80.000 156.667,80.000 141.333 C 80.000 128.953,93.318 122.567,132.000 116.399 C 152.738 113.092,233.615 116.978,254.873 122.304 M239.999 183.835 C 284.795 195.598,311.623 211.846,308.275 225.185 C 305.468 236.371,295.863 236.565,272.550 225.908 C 230.472 206.674,165.970 199.338,124.501 209.070 C 100.789 214.635,99.576 214.534,94.350 206.557 C 85.205 192.601,93.103 186.235,129.035 178.599 C 149.727 174.202,215.051 177.284,239.999 183.835 M241.333 243.740 C 273.203 254.608,285.333 262.417,285.333 272.062 C 285.333 283.943,276.265 284.897,256.785 275.063 C 222.996 258.006,173.895 252.684,127.384 261.035 C 105.258 265.008,98.667 263.240,98.667 253.333 C 98.667 234.097,194.218 227.671,241.333 243.740 " stroke="none" fill-rule="evenodd" alt="icon-spotify"></path></g></svg></a>
				<a href="https://www.instagram.com/sheepdogwolf/"><svg className="Social-media-icon" viewBox="0, 0, 400,400"><g><path id="path0" d="M158.308 3.811 C 2.413 39.338,-52.666 227.548,59.893 340.107 C 185.492 465.706,398.666 377.508,398.532 200.000 C 398.435 72.066,280.706 -24.082,158.308 3.811 M270.667 98.400 C 301.540 112.724,306.667 126.852,306.667 197.605 C 306.667 298.529,299.930 305.333,200.000 305.333 C 100.070 305.333,93.333 298.529,93.333 197.605 C 93.333 126.852,98.460 112.724,129.333 98.400 C 145.349 90.968,254.651 90.968,270.667 98.400 M135.959 118.000 C 115.640 128.299,113.484 134.945,112.394 190.667 C 111.102 256.667,114.966 270.597,137.825 282.341 C 145.795 286.435,254.205 286.435,262.175 282.341 C 285.034 270.597,288.898 256.667,287.606 190.667 C 286.129 115.179,282.588 112.000,200.000 112.000 C 151.148 112.000,147.037 112.385,135.959 118.000 M266.133 133.867 C 274.318 142.052,267.814 154.667,255.409 154.667 C 245.892 154.667,240.500 145.031,245.153 136.337 C 248.544 130.000,260.821 128.554,266.133 133.867 M222.855 149.048 C 263.022 167.291,265.801 224.655,227.603 247.040 C 185.946 271.453,133.674 231.739,146.617 185.510 C 155.865 152.481,192.178 135.114,222.855 149.048 M175.686 175.686 C 146.951 204.422,183.183 250.592,217.871 229.441 C 247.930 211.113,234.972 165.333,199.725 165.333 C 187.383 165.333,185.023 166.350,175.686 175.686 " stroke="none" fill-rule="evenodd" alt="icon-instagram"></path></g></svg></a>
				<a href="https://www.facebook.com/SheepDogWolf"><svg className="Social-media-icon" viewBox="0, 0, 400,400"><g><path id="path0" d="M152.000 5.516 C 0.073 45.185,-50.643 229.439,59.893 340.144 C 185.034 465.475,398.666 377.065,398.532 200.000 C 398.433 68.875,276.808 -27.073,152.000 5.516 M264.000 96.000 L 264.000 117.333 246.962 117.333 C 222.438 117.333,217.864 121.685,216.471 146.346 L 215.398 165.333 238.663 165.333 L 261.927 165.333 260.390 174.000 C 259.544 178.767,258.056 188.967,257.083 196.667 L 255.313 210.667 235.656 210.667 L 216.000 210.667 216.000 268.000 L 216.000 325.333 193.333 325.333 L 170.667 325.333 170.667 268.000 L 170.667 210.667 150.559 210.667 L 130.451 210.667 131.225 188.667 L 132.000 166.667 151.333 165.883 L 170.667 165.100 170.682 143.217 C 170.716 91.931,187.516 74.839,238.000 74.725 L 264.000 74.667 264.000 96.000 " stroke="none" fill-rule="evenodd" alt="icon-facebook"></path></g></svg></a>
				<a href="https://twitter.com/sheepdogwolf"><svg className="Social-media-icon" viewBox="0, 0, 400,400"><g><path id="path0" d="M160.000 3.985 C 3.298 39.331,-52.829 227.625,59.773 340.227 C 189.552 470.006,409.787 371.263,399.188 188.048 C 392.193 67.121,276.114 -22.206,160.000 3.985 M259.720 123.987 C 271.587 130.297,272.731 130.433,281.859 126.619 C 294.153 121.482,295.819 123.328,288.195 133.640 L 282.102 141.881 290.619 140.010 C 300.695 137.797,298.478 145.839,286.926 153.409 C 281.679 156.847,280.501 160.352,279.395 175.811 C 273.904 252.555,188.297 302.573,117.333 270.501 L 100.000 262.667 117.598 260.926 C 127.315 259.965,140.383 256.539,146.778 253.277 L 158.359 247.368 146.537 241.684 C 140.034 238.558,131.910 231.800,128.482 226.667 L 122.249 217.333 129.482 216.455 L 136.715 215.577 128.333 210.479 C 111.444 200.208,101.982 174.053,116.472 177.690 L 123.610 179.482 117.381 170.733 C 101.711 148.728,108.250 120.229,125.347 136.013 C 145.303 154.436,207.188 179.122,198.606 165.235 C 195.363 159.988,200.521 141.123,207.451 132.887 C 221.243 116.497,239.717 113.351,259.720 123.987 " stroke="none" fill-rule="evenodd" alt="icon-twitter"></path></g></svg></a>
			</div>
		);
	}
}

export default SocialMediaBar;