import React, { Component } from 'react';
import Image from "react-graceful-image";
import './App.css'

import press_shot from './images/press_shot.jpg'

class AboutPage extends Component {
	render() {
		return (
			<div className="About-page">
				<div>
					<Image src={press_shot} noLazyLoad="true" placeholderColor="#fff" className="About-page-press-shot" alt=""/>
					<div className="About-page-photo-text">Photo by Fraser Chatham</div>
				</div>
				<div className="About-page-text-box">
					<div className="About-page-feature-text">Sheep, Dog & Wolf is the solo project of multi-instrumentalist, vocalist, and composer Daniel McBride.</div>
					<div className="About-page-text-spacer"></div>
					<div className="About-page-body-text"> From Auckland in New Zealand, McBride is classically trained on saxophone and in composition, self-taught on a
					variety of other instruments. Bringing his technical nous to life is an acute pop sensibility, uncanny rhythmic flows and viscerally honest lyrics.
					And McBride makes every facet of his music alone.
					<br/><br/>
					<i>Ablutophobia</i>, his debut EP released at seventeen, garnered praise from sources as wide as Italian Vogue - “a rare pearl” - and The Guardian: “Curious time signatures and his beautifully incandescent voice balance an unusual amalgamation of whimsically flirtatious styles.”
					<br/><br/>
					His debut LP, <i>Egospect</i>, saw Sheep, Dog & Wolf win the Critics’ Choice Prize at the Vodafone New Zealand Music Awards, and was a finalist for the Taite Prize for Best New Zealand Album. The LP was named one of the albums of the year in Listener magazine, and the accompanying music videos for singles <i>Breathe</i> and <i>Glare</i> were each awarded Best Music Video at the Show Me Shorts film festival.
					<br/><br/>
					As a live act, Sheep, Dog & Wolf has played with CocoRosie in France, opened for Múm in the Netherlands, and toured with Angus & Julia Stone in New
					Zealand. His performances are characterised by multi-instrumental looping, emotional energy, and virtuosic jazz-inspired solos.
					<br></br><br></br>
					In 2021, McBride finally returned with his first new music in seven years; the dizzying rhythms and voices of new album <a href="https://sheepdogwolf.bandcamp.com/album/two-minds"><i>Two-Minds</i></a> showcase his unique ability to document a young life in disarray, against a complex backdrop bridging mutant R&B, post-classical minimalism and electronica.
					<br></br><br></br>
					More dextrous and ambitious than its predecessor, full of colour and energy, <i>Two-Minds</i> has received high praise since its release; described by Clash as "truly intoxicating", and by NPR as "lush and textured... so beautiful", the latter named it one of their top albums of the week. On local shores, Radio New Zealand called <i>Two-Minds</i> "a stunning, innovative album by one of Aotearoa's most genius composers," awarding it a spot in their top albums of 2021, and the record was a finalist for the Taite Prize for Best New Zealand Album.
					</div>
				</div>
			</div>
			);
	}
}

export default AboutPage;