import React, { Component } from 'react';
import './App.css'

class ContactPage extends Component {
	render() {
		return (
			<div className="Contact-page">
				<b>label</b><br></br>
				alexander@olympianmusic.co
				<br></br><br></br>
				<b>pr</b><br></br>
				billy@9pr.co.uk / lisa@thelabel.co.nz
			</div>
			);
	}
}

export default ContactPage;